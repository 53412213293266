<template>
    <div>
    <v-card class="px-5">
        <v-progress-linear v-if="isLoading"  indeterminate color="warning"></v-progress-linear>
        <card-title :is-image="false" icon="mdi-account-circle"> Approver Management</card-title>
        <v-card-text>
            <div v-if="bandsArray <= 0" class="tw-flex tw-flex-col tw-text-center my-16">
                <v-icon>mdi-account</v-icon>
                <p class="c-gray-text mb-0" >Welcome to the Approver Management section.</p>
                <p class="c-gray-text mb-0">Get started by adding a new user. <span class="c-blue-text">Click the</span></p>
                <p class="c-blue-text">button on the right to provide the necessary details.</p>
            </div>
            <div v-else>
                <v-divider></v-divider>
                <div class="tw-grid tw-grid-cols-9 mt-2 tw-border-b-2">
                    <p class="tw-col-span-2 c-gray-text  tw-text-xs tw-font-semibold">Figure Range</p>
                    <p class="tw-col-span-1 c-gray-text  tw-text-xs tw-font-semibold ">From(Band Limit)</p>
                    <p class="tw-col-span-1 c-gray-text  tw-text-xs tw-font-semibold">To(Band Limit)</p>
                    <p class="tw-col-span-4 c-gray-text  tw-text-xs tw-font-semibold">Emails</p>
                    <p class="tw-col-span-1 c-gray-text  tw-text-xs tw-font-semibold tw-justify-self-center">Action</p>
                </div>
                <div v-for="(band, i) in bandsArray" :key="i"  class="tw-grid tw-grid-cols-9 mt-2 mb-2 tw-border-b-2">
                    <p class="tw-col-span-2  tw-text-black  ">Ranges from {{ band.fromValue | formatCurrency }} to   {{ band.toValue | formatCurrency }}</p>
                    <p class="tw-col-span-1  tw-text-black ">{{ band.fromValue | formatCurrency }}</p>
                    <p class="tw-col-span-1  tw-text-black  ">{{ band.toValue | formatCurrency }}</p>
                    <p class="tw-col-span-4  tw-text-black  ">{{ band.emailAddresses }}</p>
                    <div class="tw-col-span-1 tw-space-x-4 tw-justify-self-center">
                        <v-icon :disabled="band.deleted"  color="warning" @click="editBand(band)">mdi-pencil</v-icon>
                        <v-icon :disabled="band.deleted"  color="error"  @click="openDeleteDialog(band)">mdi-close</v-icon>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent width="500px">
      <v-card class="px-4 py-4">
        <div class="tw-grid tw-grid-row tw-justify-center">
            <v-img
            class="tw-justify-self-center"
            width="50"
            src="@/assets/img/delete.png"
            ></v-img>
            <p class="c-blue-text tw-text-center mt-4 tw-text-xl">Confirm Band Deletion</p>
        </div>
        <div class="c-gray-text">Before you proceed, please be aware that deleting this band limit is an irreversible process that can't be undone.</div>
        <div>
            <v-checkbox
            v-model="check"
            label="Confirm this action by checking this box"
            ></v-checkbox>
        </div>
        <v-card-actions class="tw-grid tw-grid-col tw-justify-center">
            <v-btn @click="closeDeleteDialog"  color="primary">Cancel</v-btn>
            <v-btn color="warning" :disabled="!check" @click="deleteBand()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import axios from 'axios'
import { formatCurrency } from '@/utils/helpers'

export default {
    props: ['reload'],
    components: { CardTitle },
    data() {
        return {
        bandsArray:[],
        isLoading: false,
        check: false,
        dialog: false,
        deletionBand: null,

        }
    },
    watch: {
       reload() {
        this.getApprovalBands()
       }
    },
    methods: {
        getApprovalBands() {
          axios.get('/motor-assessment/api/approval-band/all')
          .then((res) => {
            if(res.data.success) {
            this.bandsArray = res.data.data
            } else {
                this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: res.data.msg,
              variant: 'error',
            })
            }
          })
          .catch((err) => {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: err.message,
              variant: 'error',
            })
          })
        },
        editBand(band) {
            this.$emit('editBand',band)
        },
        openDeleteDialog(band) {
          this.deletionBand = band
          this.dialog = true
        },
        closeDeleteDialog() {
          this.deletionBand = null
          this.dialog = false
        },
        deleteBand() {
            this.isLoading = true
            axios.delete(`/motor-assessment/api/approval-band/delete/${this.deletionBand.id}/${this.$store.state.auth.user.sub}`)
            .then((res) => {
                if(res.data.success) {
          this.$store.commit('SNACKBAR', {
              state: 'success',
              message: res.data.msg,
              variant: 'success',
            })
            this.isLoading = false
            this.getApprovalBands()
        } else {
                  this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: res.data.msg,
              variant: 'error',
            })
            this.isLoading = false
        }
       })
       .catch((err) => {
        this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: err.message,
              variant: 'error',
            })
            this.isLoading = false
       })
        }
    },
    mounted() {
        this.getApprovalBands()
    },    
    filters: {
    formatCurrency,
  },
}
</script>
